body {
  margin: 0;
  min-height: 100vh !important;
  color: #353535;
  font-family: "Roboto", sans-serif;
}

#root {
  height: 100%;
}

.app {
  min-height: 100%;
  display: flex;
  position: relative;
}

.chakra-form__required-indicator {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

/* navbar */
div.navbar-menu > div {
  right: 0;
}

.require-field {
  margin-left: 0.25rem;
  color: #E53E3E;
}
